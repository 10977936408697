import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';

async function initialize() {
  if (environment.production) {
    enableProdMode();
  }

  const appModuleContainer = await import('./app/app.module');

  platformBrowserDynamic()
    .bootstrapModule(appModuleContainer.AppModule)
    .catch((err) => console.error(err));
}

void initialize();
